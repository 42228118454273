import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button as MUIButton, Typography, Skeleton } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useMixpanel from 'hooks/useMixpanel';
import useParamsSetFilters from 'hooks/useParamsSetFilters';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ConnectionInput,
  ContentFilter,
  OnDemandPreviewFragment,
  useFilterContentsLazyQuery,
  useGetContentAttributesQuery,
} from 'graphql/types';
import logger from 'utils/logger';
import { contentFilterQueryUpdater } from 'utils/queryUpdaters';
import ContentList from 'components/ContentList';
import { Section, useClassFilterContext } from 'contexts/classFilterContext';
import { usePlaybookTheme } from 'playbook';
import useSplit from 'hooks/useSplit';
import Filters from './Filters';
import { formatFilters } from './Filters/utils';
import { getOnDemandClickEventName } from './utils';

export type FetchContentProps = {
  filter: ContentFilter;
  input?: ConnectionInput;
};

type Props = {
  disableBrandFilter?: boolean;
  valueKey?: Section;
};

const OnDemandList = ({ disableBrandFilter, valueKey = 'onDemand' }: Props) => {
  const navigate = useNavigate();
  const track = useMixpanel();
  const theme = usePlaybookTheme();
  const { flags } = useSplit();
  const location = useLocation();

  const { state } = useClassFilterContext();
  const [filtersOpen, setFiltersOpen] = useState(false);
  useParamsSetFilters();

  const showArticleAndAudios = flags['fit-show-audio-article-on-web'] ?? false;

  // This is needed to display the content modal if linked directly
  const { contentId: openContentId } = useParams<{ contentId: string }>();
  const [contentIdToShow, setContentIdToShow] = useState(openContentId);

  const { data: filtersData } = useGetContentAttributesQuery({
    fetchPolicy: 'cache-first',
  });

  // REACT to location changes in contentId
  useEffect(() => {
    if (openContentId) {
      setContentIdToShow(openContentId);
    } else {
      setContentIdToShow('');
    }
  }, [openContentId]);

  const onModalClose = () => {
    const canGoBack = location.key !== 'default';
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  const { formattedFilters, filtersCount } = useMemo(() => {
    // Tags is a representation of the user selection before being parsed/formatted
    const tags = {
      brands: [] as string[],
      genres: [] as string[],
      durations: [] as string[],
      levels: [] as string[],
      splits: [] as string[],
      equipment: [] as string[],
      intensities: [] as string[],
      formats: [] as string[],
      ...state[valueKey],
    };

    // If the flag is off, show only videos
    if (showArticleAndAudios === false) {
      tags.formats = ['video'];
    }

    // Formatted filters is the value as sent to the backend
    const internalFormattedFilters = formatFilters(
      tags,
      state.availableOnDemand.brands ?? [],
      filtersData?.contentEquipment ?? [],
      filtersData?.contentGenres ?? [],
      filtersData?.contentLevels ?? [],
      filtersData?.contentSplits ?? [],
      filtersData?.contentIntensities ?? [],
    ) as Record<string, string[]>;

    const brandCount: number = disableBrandFilter ? 0 : tags?.brands?.length;
    const equipmentCount: number = tags?.equipment?.length ?? 0;
    const levelsCount: number = tags?.levels?.length ?? 0;
    const splitsCount: number = tags?.splits?.length ?? 0;
    const durationCount: number = tags?.durations?.length ?? 0;
    const intensitiesCount: number = tags?.intensities?.length ?? 0;
    const formatsCount: number = tags?.formats?.length ?? 0;

    let totalCount: number =
      brandCount +
      durationCount +
      equipmentCount +
      levelsCount +
      splitsCount +
      intensitiesCount;

    if (showArticleAndAudios) {
      totalCount += formatsCount;
    }

    return {
      tags,
      filtersCount: totalCount,
      formattedFilters: internalFormattedFilters,
    };
  }, [
    state,
    valueKey,
    filtersData?.contentEquipment,
    filtersData?.contentGenres,
    filtersData?.contentLevels,
    filtersData?.contentSplits,
    filtersData?.contentIntensities,
    showArticleAndAudios,
    disableBrandFilter,
  ]);

  // Manage loading manually to avoid showing skeletons when not intended
  const [loading, setLoading] = useState(true);
  const [filterContent, { data: classData, fetchMore }] =
    useFilterContentsLazyQuery({
      onError: (e) => {
        logger.debug('List: useFilterContentsLazyQuery', e);
      },
      // Consolidate incoming data after the query completes
      onCompleted: () => {
        setLoading(false);
      },
    });

  const contents = classData?.contents?.nodes ?? [];

  const { pageInfo } = classData?.contents || {};
  const fetchClasses = useCallback(
    async ({ filter, input }: FetchContentProps) =>
      filterContent({
        variables: {
          filter,
          input,
        },
      }),
    [filterContent],
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchClasses({
        filter: formattedFilters,
      });
    })();
  }, [formattedFilters, fetchClasses]);

  const handleOnEndReached = async () => {
    if (pageInfo?.hasNextPage) {
      await fetchMore({
        variables: {
          filter: formattedFilters,
          input: {
            after: pageInfo?.endCursor,
          },
        },
        updateQuery: contentFilterQueryUpdater,
      });
    }
  };

  const handleClick = (item: OnDemandPreviewFragment) => {
    track('User Action', {
      event_name: getOnDemandClickEventName(item.__typename),
      event_type: 'screen interaction',
      event_category: 'on demand',
      event_location: 'home',
      content_id: item.id,
    });
  };

  const filtersBtnClick = () => {
    setFiltersOpen(true);
    track('User Action', {
      event_name: 'view_filter_modal',
      event_type: 'screen interaction',
      event_category: 'on demand',
      event_location: 'home',
    });
  };

  return (
    <div>
      <ResultBar>
        <Filter
          size="small"
          startIcon={
            <FontAwesomeIcon
              icon={faSliders}
              width={20}
              color={theme.colors.gray[900]}
            />
          }
          onClick={filtersBtnClick}
          disableRipple
        >
          Filter
          {filtersCount ? ` (${filtersCount})` : ''}
        </Filter>
        <Filters
          valueKey="onDemand"
          open={filtersOpen}
          onClose={() => setFiltersOpen(false)}
        />
        <ClassCount>
          {loading ? (
            <Skeleton variant="text" width={70} />
          ) : (
            `${classData?.contents.count} Results`
          )}
        </ClassCount>
      </ResultBar>
      <ContentList
        handleOnEndReached={handleOnEndReached}
        pageInfo={pageInfo}
        loading={loading}
        handleClick={handleClick}
        contents={contents}
        contentIdToShow={contentIdToShow}
        onModalClose={onModalClose}
      />
    </div>
  );
};

export default OnDemandList;

const ResultBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.baseUnit * 1,
}));

const Filter = styled(MUIButton)(({ theme }) => ({
  ...theme.typography.textVariant.button.s,
  color: theme.colors.alias.textPrimary,
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
  marginTop: '-10px',
  // Negative top margin adjusts for padding added by MUIButton.
  // Reducing the padding itself would shrink the tappable area.
}));

const ClassCount = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textPrimary,
}));
