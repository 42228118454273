import React, { useRef } from 'react';
import {
  faPauseCircle,
  faPlayCircle,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Forward } from 'assets/icons/forward-15.svg';
import { ReactComponent as Rewind } from 'assets/icons/rewind-15.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from '@emotion/styled';
import { usePlaybookTheme } from 'playbook';
import { Typography } from '@mui/material';
import { noop } from 'lodash';
import { MPEventType } from '@teamexos/fit-shared';
import useMixpanel from 'hooks/useMixpanel';

const JUMP_STEP = 15000; // milliseconds

const AudioPlayerStyles = styled.div(({ theme }) => ({
  '.rhap_container': {
    width: 450,
    boxShadow: 'none',
  },
  '.rhap_time': {
    ...theme.typography.textVariant.label.s,
  },
  '.rhap_progress-bar': {
    backgroundColor: theme.colors.aquamarine[50],
  },
  '.rhap_progress-container': {
    marginRight: theme.baseUnit * 2,
    marginLeft: theme.baseUnit * 2,
  },
  '.rhap_progress-indicator': {
    backgroundColor: theme.colors.alias.secondary,
  },
  '.rhap_progress-filled': {
    backgroundColor: theme.colors.alias.secondary,
  },
  '.rhap_play-pause-button': {
    height: 60,
    width: 60,
    marginLeft: theme.baseUnit,
    marginRight: theme.baseUnit,
  },
}));

const AudioPlayerWrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.alias.mainBackgroundDivider}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.baseUnit * 3,
  paddingBottom: theme.baseUnit * 3,
  flexDirection: 'column',
}));

const ViewTranscriptButton = styled.button(({ theme }) => ({
  ...theme.typography.textVariant.label.m,
  border: 'none',
  background: 'none',
  color: theme.colors.alias.primary,
  cursor: 'pointer',
  padding: 0,
  display: 'flex',
  gap: theme.baseUnit,
  marginTop: theme.baseUnit * 3,
}));

const TranscriptContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.named.exosGray,
  borderRadius: theme.baseUnit,
  padding: theme.baseUnit * 2,
  marginLeft: theme.baseUnit * 12,
  marginRight: theme.baseUnit * 12,
  marginTop: theme.baseUnit * 3,
}));

const TranscriptText = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.body.m,
  whiteSpace: 'pre-line',
}));

type AudioPlayerProps = {
  audio: string;
  transcript?: string | null;
  width: number;
  onProgress?: (progress: number) => void;
  mixpanelData?: Record<string, any>;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  audio,
  transcript,
  width,
  onProgress = noop,
  mixpanelData,
}) => {
  const audioPlayerRef = useRef(null);

  const theme = usePlaybookTheme();
  const [transcriptIsVisible, setTranscriptIsVisible] = React.useState(false);
  const track = useMixpanel();

  const handleProgress = (e: Event) => {
    const audioElement = e.target as HTMLAudioElement;
    const progress = audioElement.currentTime / audioElement.duration;
    onProgress(progress);
  };

  const trackAction = (
    name: string,
    type: MPEventType,
    lastPosition?: number,
  ) => {
    const data = {
      ...mixpanelData,
      event_name: name,
      event_type: type,
      audio_timestamp: lastPosition,
    };

    track('User Action', data);
  };

  const onPause = (e: Event) => {
    const time = (e.target as HTMLAudioElement).currentTime;
    trackAction('audio_pause', 'screen interaction', time);
  };

  const onPlay = (e: Event) => {
    const time = (e.target as HTMLAudioElement).currentTime;
    trackAction('audio_play', 'screen interaction', time);
  };

  const onSeeked = (e: Event) => {
    const time = (e.target as HTMLAudioElement).currentTime;
    trackAction('audio_seek', 'screen interaction', time);
  };

  const onClickViewTranscript = () => {
    trackAction(
      transcriptIsVisible ? 'hide_transcript' : 'show_transcript',
      'screen interaction',
    );
    setTranscriptIsVisible(!transcriptIsVisible);
  };

  return (
    <AudioPlayerWrapper style={{ width }} data-testid="audio-player">
      <AudioPlayerStyles>
        <H5AudioPlayer
          ref={audioPlayerRef}
          showSkipControls={false}
          showDownloadProgress={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          autoPlay={false}
          src={audio}
          listenInterval={1000}
          progressJumpSteps={{ forward: JUMP_STEP, backward: JUMP_STEP }}
          layout="stacked-reverse"
          onPlay={onPlay}
          onPause={onPause}
          onSeeked={onSeeked}
          onListen={handleProgress}
          customIcons={{
            play: (
              <FontAwesomeIcon
                icon={faPlayCircle}
                size="lg"
                width={56}
                color={theme.colors.alias.secondary}
              />
            ),
            forward: <Forward />,
            rewind: <Rewind />,
            pause: (
              <FontAwesomeIcon
                icon={faPauseCircle}
                size="lg"
                width={56}
                color={theme.colors.alias.secondary}
              />
            ),
          }}
        />
      </AudioPlayerStyles>
      <ViewTranscriptButton onClick={onClickViewTranscript}>
        <FontAwesomeIcon
          icon={transcriptIsVisible ? faChevronUp : faChevronDown}
          size="lg"
        />
        View Transcript
      </ViewTranscriptButton>
      {transcriptIsVisible && transcript && (
        <TranscriptContainer>
          <TranscriptText>{transcript}</TranscriptText>
        </TranscriptContainer>
      )}
    </AudioPlayerWrapper>
  );
};

export default AudioPlayer;
