import {
  Section,
  CATCH_ALL_GENRE,
  useClassFilterContext,
} from 'contexts/classFilterContext';
import slugify from 'slugify';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Genre,
  useGetContentAttributesQuery,
  useGetOnDemandSectionsQuery,
} from 'graphql/types';
import logger from 'utils/logger';
import { durations as durationList } from 'components/onDemand/Filters';

// Web routing has some bad UX side effects bc the shared ClassFilterContext
// expects similar UX/component heirarchy to mobile.
// We should reduce maintenance of Genre & Collection state here by reducing
// the Location's upstream effects on ClassFilterContext
interface ParamFiltersType {
  genres?: string[];
  brands?: string[];
  levels?: string[];
  splits?: string[];
  equipment?: string[];
  durations?: string[];
  intensities?: string[];
  formats?: string[];
}

const useParamsSetFilters = (
  valueKey: Section = 'onDemand',
): {
  loading: boolean;
  genre: {
    path: string | null;
    name: Genre['name'] | null;
  };
  brand: string | null;
} => {
  const { state, setFilters, setAvailableFilters } = useClassFilterContext();
  const [searchParams] = useSearchParams();

  const brandQueryParam = searchParams.get('brand');
  const genreQueryParam = searchParams.get('genre');
  const levelQueryParam = searchParams.getAll('level');
  const splitQueryParam = searchParams.getAll('target_area');
  const equipmentQueryParam = searchParams.getAll('equipment');
  const durationQueryParam = searchParams.getAll('duration');
  const intensityQueryParam = searchParams.getAll('intensity');
  const formatQueryParam = searchParams.getAll('format');

  /**
   * Set available genres and equipment
   */
  const { data: filtersData, loading: loadingFiltersData } =
    useGetContentAttributesQuery({
      fetchPolicy: 'cache-first',
      onError: (error) => {
        logger.debug('Categories: useGetContentAttributesQuery', error);
      },
    });

  const brandList = useMemo(
    () => state?.availableOnDemand.brands ?? [],
    [state?.availableOnDemand.brands],
  );

  const genresList = useMemo(
    () => filtersData?.contentGenres ?? [],
    [filtersData?.contentGenres],
  );

  const levelsList = useMemo(
    () => filtersData?.contentLevels ?? [],
    [filtersData?.contentLevels],
  );

  const splitsList = useMemo(
    () => filtersData?.contentSplits ?? [],
    [filtersData?.contentSplits],
  );

  const equipmentList = useMemo(
    () => filtersData?.contentEquipment ?? [],
    [filtersData?.contentEquipment],
  );

  const intensitiesList = useMemo(
    () => filtersData?.contentIntensities ?? [],
    [filtersData?.contentIntensities],
  );

  useEffect(() => {
    const formattedGenres = genresList.map((g) => g.name);
    const formattedLevels = levelsList.map((l) => l.name);
    const formattedSplits = splitsList.map((s) => s.name);
    const formattedEquipment = equipmentList.map((e) => e.name);
    const formattedIntensities = intensitiesList.map((i) => i.name);

    const nextAvailableFilters = {
      ...state.availableOnDemand,
      genres: formattedGenres,
      levels: formattedLevels,
      splits: formattedSplits,
      equipment: formattedEquipment,
      intensities: formattedIntensities,
    };

    if (!isEqual(nextAvailableFilters, state.availableOnDemand)) {
      setAvailableFilters('availableOnDemand', nextAvailableFilters);
    }
  }, [
    genresList,
    state.availableOnDemand,
    setAvailableFilters,
    equipmentList,
    levelsList,
    splitsList,
    intensitiesList,
  ]);

  /**
   * Set available brands
   */
  const { data: collectionContent } = useGetOnDemandSectionsQuery({
    variables: {
      slug: 'classes/fit-on-demand',
    },
    onError: (error) => {
      logger.debug('Experiences: useGetOnDemandSectionsQuery', error);
    },
  });

  useEffect(() => {
    const brands = (collectionContent?.contentCollection?.sections.nodes
      .map((section) => section.brand)
      .filter((brand) => brand) ?? []) as string[];

    const formattedBrands = brands.map((brand) => brand.replace('_', ' '));

    const nextBrands = {
      ...state.availableOnDemand,
      brands: formattedBrands,
      durations: durationList,
    };

    if (
      formattedBrands.length > 0 &&
      !isEqual(nextBrands, state.availableOnDemand)
    ) {
      setAvailableFilters('availableOnDemand', nextBrands);
    }
  }, [
    collectionContent?.contentCollection,
    state.availableOnDemand,
    setAvailableFilters,
  ]);

  const currentGenre = useMemo(() => {
    const allChildren = genresList.flatMap((g) => g.children);
    const current = loadingFiltersData
      ? null
      : ([...genresList, ...allChildren].find(
          (c) => c.value === genreQueryParam?.toLowerCase(),
        ) ?? CATCH_ALL_GENRE);

    return current;
  }, [loadingFiltersData, genresList, genreQueryParam]);

  const currentLevel = useMemo(() => {
    const current = loadingFiltersData
      ? []
      : (levelsList.filter((l) => levelQueryParam.includes(l.value)) ?? null);
    return current;
  }, [loadingFiltersData, levelsList, levelQueryParam]);

  const currentSplit = useMemo(() => {
    const current = loadingFiltersData
      ? []
      : (splitsList.filter((l) => splitQueryParam.includes(l.value)) ?? null);
    return current;
  }, [loadingFiltersData, splitsList, splitQueryParam]);

  const currentIntensity = useMemo(() => {
    const current = loadingFiltersData
      ? []
      : (intensitiesList.filter((l) => intensityQueryParam.includes(l.value)) ??
        null);
    return current;
  }, [loadingFiltersData, intensitiesList, intensityQueryParam]);

  const currentEquipment = useMemo(() => {
    const current = loadingFiltersData
      ? []
      : (equipmentList.filter((e) => equipmentQueryParam.includes(e.value)) ??
        null);
    return current;
  }, [loadingFiltersData, equipmentList, equipmentQueryParam]);

  const currentDurations = useMemo(() => {
    const formattedDurations = durationQueryParam?.map((param) =>
      param === '45_min' ? '45+ min' : param.replace('_', ' '),
    );
    const current = loadingFiltersData
      ? []
      : (durationList.filter((d) => formattedDurations?.includes(d)) ?? null);
    return current;
  }, [loadingFiltersData, durationQueryParam]);

  const currentBrand = useMemo(() => {
    const current = loadingFiltersData
      ? []
      : (brandList.filter((brand) =>
          brandQueryParam?.includes(
            slugify(brand, { replacement: '_', lower: true }),
          ),
        ) ?? null);
    return current;
  }, [loadingFiltersData, brandList, brandQueryParam]);

  const currentFormat = formatQueryParam ?? null;

  /**
   * REACT TO LOCATION BAR PARAMS, SET FILTERS ACCORDINGLY
   */
  useEffect(() => {
    const onDestroy = () => {
      setFilters(valueKey, {});
    };

    if (
      currentGenre === null &&
      currentBrand === null &&
      currentLevel === null &&
      currentSplit === null &&
      currentEquipment === null &&
      currentDurations === null &&
      currentIntensity === null &&
      currentFormat === null
    ) {
      return onDestroy;
    }

    const newFilters: ParamFiltersType = {};

    if (currentGenre?.value) {
      newFilters.genres = [currentGenre.value];
    }

    if (currentBrand) {
      newFilters.brands = currentBrand;
    }

    if (currentLevel.length > 0) {
      newFilters.levels = currentLevel.map((e) => e.name);
    }

    if (currentSplit.length > 0) {
      newFilters.splits = currentSplit.map((s) => s.name);
    }

    if (currentIntensity.length > 0) {
      newFilters.intensities = currentIntensity.map((s) => s.name);
    }

    if (currentEquipment.length > 0) {
      newFilters.equipment = currentEquipment.map((e) => e.name);
    }

    if (currentDurations.length > 0) {
      newFilters.durations = currentDurations.map((d) => d.replace('_', ' '));
    }

    if (currentFormat.length > 0) {
      newFilters.formats = currentFormat;
    }

    setFilters(valueKey, newFilters);
    return onDestroy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentGenre,
    currentBrand,
    // currentLevel, // Triggers rerender loop
    // currentEquipment, // Triggers rerender loop
    // currentDurations, // Triggers rerender loop
    // setFilters, // Triggers rerender loop
    // currentIntensity, // Triggers rerender loop
    valueKey,
  ]);

  useEffect(() => {
    const {
      brands,
      genres,
      equipment,
      levels,
      splits,
      durations,
      intensities,
      formats,
    } = state.onDemand;
    // Need to remove View all so that it doesn't get pushed to the search params
    const filteredOnDemandGenres = genres?.filter(
      (genre) => genre !== CATCH_ALL_GENRE.value,
    );
    const newParams = new URLSearchParams();

    if (levels && levels.length > 0) {
      levels.forEach((param) => newParams.append('level', param));
    }
    if (splits && splits.length > 0) {
      splits.forEach((param) => newParams.append('target_area', param));
    }
    if (equipment && equipment.length > 0) {
      equipment.forEach((param) => newParams.append('equipment', param));
    }
    if (brands && brands.length > 0) {
      brands.forEach((param) => newParams.append('brand', param));
    }
    if (durations && durations.length > 0) {
      durations.forEach((param) =>
        newParams.append('duration', param.replace('+', '')),
      );
    }
    if (intensities && intensities.length > 0) {
      intensities.forEach((param) => newParams.append('intensity', param));
    }
    if (filteredOnDemandGenres && filteredOnDemandGenres.length > 0) {
      newParams.set('genre', filteredOnDemandGenres[0]);
    }

    if (formats && formats.length > 0) {
      formats.forEach((param) => newParams.append('format', param));
    }

    const formattedNewParams = newParams
      .toString()
      .replace(/\+/g, '_')
      .toLowerCase();

    if (formattedNewParams.length > 0) {
      window.history.replaceState({}, '', `?${formattedNewParams}`);
    }
  }, [state.onDemand]);

  return {
    loading: false,
    genre: {
      // we need to keep the path for components that require a genre param/path
      name: currentGenre?.name || null,
      path: currentGenre?.value || null,
    },
    brand: currentBrand[0],
  };
};

export default useParamsSetFilters;
