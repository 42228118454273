import React, { useCallback, useEffect, useRef } from 'react';
import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';

import { baseUnit, light, colors } from 'playbook/theme';
import { Props } from 'html-react-parser/lib/attributes-to-props';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const { typography } = light;

const APP_SCHEME_URL = 'exosfit://';

const verticalRhythm = {
  marginBottom: 0,
  marginTop: baseUnit * 2,
  padding: 0,
};

const defaultHeadingStyle = {
  ...verticalRhythm,
  color: colors.alias.textPrimary,
};

const tagStyles = {
  a: {
    ...typography.fontFamily.reader700,
    color: colors.alias.textPrimary,
    textDecorationColor: colors.alias.textPrimary,
    textDecorationLine: 'underline',
  },
  h1: {
    ...typography.textVariant.title.s,
    ...defaultHeadingStyle,
    marginTop: baseUnit * 4,
  },
  h2: {
    ...typography.textVariant.title.xs,
    ...defaultHeadingStyle,
    marginTop: baseUnit * 4,
  },
  h3: {
    ...typography.textVariant.label.l,
    ...defaultHeadingStyle,
    marginTop: baseUnit * 4,
  },
  h4: {
    ...typography.textVariant.label.m,
    ...defaultHeadingStyle,
    marginTop: baseUnit * 4,
  },
  h5: {
    ...typography.textVariant.title.s,
    ...defaultHeadingStyle,
    marginBottom: baseUnit * 2,
    lineHeight: 32,
  },
  p: {
    ...typography.textVariant.body.m,
    ...verticalRhythm,
    marginTop: baseUnit,
    color: colors.alias.textSecondary,
  },
  div: {
    ...typography.textVariant.body.m,
    color: colors.alias.textSecondary,
  },
  blockquote: {
    ...typography.textVariant.body.m,
    ...verticalRhythm,
    color: colors.alias.textSecondary,
    borderLeft: `solid 2px ${colors.aquamarine[400]}`,
    paddingLeft: baseUnit * 3,
    marginLeft: 0,
    marginVertical: baseUnit * 4,
  },
  img: {
    ...verticalRhythm,
    width: '100%',
    height: 'auto',
  },
  strong: {
    color: colors.alias.textPrimary,
  },
  i: {
    ...typography.fontFamily.reader400italic,
  },
  ul: {
    marginLeft: 12,
    paddingLeft: 12,
    marginTop: baseUnit,
    marginBottom: baseUnit / 2,
  },
  ol: {
    marginLeft: 12,
    paddingLeft: 14,
    marginTop: baseUnit,
    marginBottom: 0,
  },
  li: {
    paddingLeft: 4,
  },
  hr: {
    borderColor: colors.alias.mainSurfaceDivider,
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderTopWidth: 1,
    marginVertical: baseUnit * 4,
  },
};

const ImageCaption = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textSecondary,
  marginTop: theme.baseUnit,
}));

const ImageContainer = styled.div(({ theme }) => ({
  marginBottom: theme.baseUnit * 4,
}));

type ExosArticleImageProps = {
  src: string;
  alt: string;
  extra_large: string;
  caption: string;
  containerWidth: number;
  contentWidth: number;
};

const ExosArticleImage = (props: ExosArticleImageProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { src, alt, extra_large, caption, containerWidth, contentWidth } =
    props;
  const extraLarge = extra_large === 'true';

  const extraLargeMargin = (contentWidth - containerWidth) / 2; // negative margin to make the image same width as the container, not the content

  const imgStyle = {
    width: `${contentWidth}px`,
    height: 'auto',
    alignSelf: 'center',
    marginLeft: 0,
  };

  if (extraLarge) {
    imgStyle.marginLeft = extraLargeMargin;
    imgStyle.width = `${containerWidth}px`;
  }

  if (typeof src !== 'string') {
    return null;
  }

  return (
    <ImageContainer>
      <img
        src={src}
        alt={typeof alt === 'string' ? alt : ''}
        style={imgStyle}
      />
      {caption && (
        <ImageCaption
          style={{ paddingLeft: extraLarge ? extraLargeMargin : 0 }}
        >
          {caption}
        </ImageCaption>
      )}
    </ImageContainer>
  );
};

const anchorReplacements = (props: Props) => {
  const newProps = { ...props };
  newProps.target = props.target ?? '_blank'; // default for new tab
  // replace scheme url with web url
  if (typeof props.href === 'string' && props.href.startsWith(APP_SCHEME_URL)) {
    const { host, protocol } = window.location;
    newProps.href = props.href.replace(APP_SCHEME_URL, `${protocol}//${host}/`);
  }
  return newProps;
};

const customHtmlParser = (
  html: string,
  containerWidth: number,
  contentWidth: number,
) => {
  const tagStylesList = Object.keys(tagStyles);

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        if (tagStylesList.includes(domNode.name)) {
          const cssStyle = tagStyles[domNode.name as keyof typeof tagStyles];
          const ElementName = domNode.name as keyof JSX.IntrinsicElements;
          let props = attributesToProps(domNode.attribs);

          if (ElementName === 'a') {
            props = anchorReplacements(props);
          }

          if (domNode.children.length === 0) {
            return <ElementName style={cssStyle} {...props} />;
          }

          return (
            <ElementName style={cssStyle} {...props}>
              {domToReact(domNode.children as DOMNode[], options)}{' '}
            </ElementName>
          );
        }

        if (domNode.name === 'exos-article-image') {
          const articleImageProps = {
            ...domNode.attribs,
            containerWidth,
            contentWidth,
          } as ExosArticleImageProps;

          return <ExosArticleImage {...articleImageProps} />;
        }
      }

      return domNode;
    },
  };

  const parsedHtml = parse(html, options);
  return parsedHtml;
};

type HtmlContentProps = {
  html: string;
  containerWidth: number;
  contentWidth: number;
  onProgress?: (progress: number) => void;
};

const HtmlContent: React.FC<HtmlContentProps> = ({
  html,
  containerWidth,
  contentWidth,
  onProgress = () => {},
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const parsedHtml = customHtmlParser(html, containerWidth, contentWidth);

  const handleScroll = useCallback(() => {
    if (componentRef.current) {
      const componentRect = componentRef.current.getBoundingClientRect();

      // Calculate how much of the component is in the viewport
      const componentHeight = componentRect.height;
      const componentTop = componentRect.top;
      const viewportHeight = window.innerHeight;

      // If the component is completely above the viewport, scrollProgress will be 0
      // If the component is completely inside the viewport, scrollProgress will be 100
      const progress = Math.min(
        Math.max(0, (viewportHeight - componentTop) / componentHeight),
        1,
      );

      onProgress(progress);
    }
  }, [onProgress]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div ref={componentRef} style={{ maxWidth: contentWidth }}>
      {parsedHtml}
    </div>
  );
};

export default HtmlContent;
