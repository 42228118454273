import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Skeleton,
  Typography,
} from '@mui/material';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark-circle-green.svg';
import { usePlaybookTheme } from 'playbook';
import { PhotoBasicsFragment } from 'graphql/types';
import { computeUrl } from 'utils/image';
import { startViewTransition } from 'utils/helpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookmarkButton from './common/BookmarkButton';
import { CONTENT_TILE_ICONS } from './onDemand/utils';

const Checkmark = styled(CheckmarkIcon)(({ theme }) => ({
  position: 'absolute',
  left: theme.baseUnit * 2,
  top: theme.baseUnit * 2,
}));

const Next = styled('span')(({ theme }) => ({
  ...theme.typography.fontFamily.sharp700,
  fontSize: 12,
  lineHeight: '12px',
  marginBottom: theme.baseUnit,
  color: theme.colors.aquamarine[300],
}));

export const MEDIA_AR = 1.5; // width relative to height

const imgBreakpoints = {
  xs: { screenWidth: 743, width: 216 },
  sm: { screenWidth: 768, width: 224 },
  md: { screenWidth: 1024, width: 310 },
  lg: { screenWidth: 1440, width: 330 },
  xl: { screenWidth: 1920, width: 450 },
};
const sizes = Object.values(imgBreakpoints)
  .map(({ screenWidth, width }) => `(max-width: ${screenWidth}px) ${width}px`)
  .join(', ');

type Props = {
  contentId?: string;
  image?: PhotoBasicsFragment | null;
  title?: string;
  onClick?: () => void;
  skeleton?: boolean;
  checked?: boolean;
  orderNumber?: number;
  saved?: boolean | null;
  mixpanelData?: Record<string, unknown>;
  routePrefix?: string;
  type?: string;
  genre?: string;
  time?: string | null;
  level?: string;
} & CardProps;

const ContentTile: React.FC<Props> = ({
  contentId,
  image,
  title,
  onClick,
  checked,
  orderNumber,
  skeleton,
  saved,
  mixpanelData,
  routePrefix = 'ondemand',
  type,
  time,
  genre,
  level,
  ...rest
}) => {
  const theme = usePlaybookTheme();

  const minWidth = imgBreakpoints.xs.width;

  const showBookmark = typeof saved === 'boolean' && contentId;

  const iconType = type && CONTENT_TILE_ICONS[type.toLowerCase()];

  const onTileClick = useCallback(() => {
    startViewTransition();
    onClick?.();
  }, [onClick]);

  if (skeleton) {
    return (
      <CardContainer square {...rest}>
        <Skeleton height="100%" variant="rectangular" />
      </CardContainer>
    );
  }

  const srcSet = Object.values(imgBreakpoints)
    .map(({ screenWidth, width }) => {
      const url = computeUrl(image, {
        width,
        height: width / MEDIA_AR,
      });
      return `${url} ${screenWidth}w`;
    })
    .join(', ');

  return (
    <CardContainer square {...rest} style={{ minWidth }}>
      <CardActionArea
        component={Link}
        to={`/${routePrefix}/${contentId}`}
        onClick={onTileClick}
        style={{ height: '100%', overflow: 'hidden' }}
      >
        <CardMedia
          component="img"
          loading="lazy"
          srcSet={srcSet}
          src={computeUrl(image, {
            width: imgBreakpoints.xl.width,
            height: imgBreakpoints.xl.width / MEDIA_AR,
          })}
          sizes={sizes}
          // we don't want to provide an alt text for decorative images
          // but we need to provide an empty string to pass a11y checks
          alt=""
          style={{ borderRadius: theme.baseUnit }}
        />

        {(image?.preview ?? '').length > 0 && (
          <CardMedia
            component="img"
            loading="eager"
            src={image?.preview ?? ''}
            // height="100%"
            // width="100%"
            // we don't want to provide an alt text for decorative images
            // but we need to provide an empty string to pass a11y checks
            alt=""
            style={{
              borderRadius: theme.baseUnit,
              height: '100%',
              width: '100%',
            }}
          />
        )}

        <TextContainer>
          {checked && <Checkmark style={{}} />}
          {orderNumber === 0 && checked === false && <Next>Up next</Next>}
          <Title role="heading" aria-level={3}>
            {title}
          </Title>
          <Metadata>
            {iconType && (
              <FontAwesomeIcon
                key="icon"
                icon={iconType}
                style={{ marginRight: 8 }}
              />
            )}
            <span key="min">{Math.floor(Number(time))} min · </span>
            <span key="meta" style={{ textTransform: 'capitalize' }}>
              {[`${genre ?? ''}`, `${level ?? ''}`].filter(Boolean).join(' · ')}
            </span>
          </Metadata>
        </TextContainer>
      </CardActionArea>
      {showBookmark && (
        <BookmarkContainer>
          <BookmarkButton
            contentId={contentId}
            isSaved={saved}
            mixpanelData={mixpanelData}
          />
        </BookmarkContainer>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Card)(({ theme }) => ({
  aspectRatio: `${MEDIA_AR}`,
  borderRadius: theme.baseUnit,
  overflow: 'visible', // overflow visible and border-radius applied here and on the image beneath in order to normalize the focus behavior
  position: 'relative',
}));
const TextContainer = styled(CardContent)(() => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  boxSizing: 'border-box',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  backgroundImage:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 53.12%, rgba(0, 0, 0, 0.47) 85.94%)',
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.xs,
  color: theme.colors.alias.textContrast,
  marginBottom: theme.baseUnit,
}));

const Metadata = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.xs,
  lineHeight: 1,
  color: theme.colors.alias.textContrast,
}));

const BookmarkContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.baseUnit * 2,
  top: theme.baseUnit * 2,
}));

export default ContentTile;
