import React, { createContext, useContext, useMemo, useReducer } from 'react';

export const CATCH_ALL_GENRE = { name: 'All Genres', value: 'all' };

export interface ClassFiltersT {
  brands?: string[];
  genres?: string[];
  durations?: string[];
  equipment?: string[];
  levels?: string[];
  splits?: string[];
  intensities?: string[];
  formats?: string[];
}

export interface IState {
  onDemand: ClassFiltersT;
  live: ClassFiltersT;
  availableOnDemand: ClassFiltersT;
}

export type Section = 'onDemand' | 'live';
export type AvailableSection = 'availableOnDemand';

export type Action =
  | {
      type: 'SET_FILTERS';
      section: Section;
      filters: ClassFiltersT;
    }
  | {
      type: 'CLEAR_FILTERS';
      section: Section;
    }
  | {
      type: 'SET_AVAILABLE_FILTERS';
      section: AvailableSection;
      filters: ClassFiltersT;
    };

export const defaultState: IState = {
  availableOnDemand: {
    brands: [],
    genres: [],
    durations: [],
    intensities: [],
  },
  onDemand: {
    brands: [],
    genres: [],
    durations: [],
    intensities: [],
  },
  live: {
    genres: [],
    durations: [],
  },
};

export interface IContext {
  state: IState;
  setFilters: (section: Section, filters: ClassFiltersT) => Promise<void>;
  setAvailableFilters: (
    section: AvailableSection,
    filters: ClassFiltersT,
  ) => Promise<void>;
  clearFilters: (section: Section) => Promise<void>;
}

export const defaultContext: IContext = {
  state: defaultState,
  setFilters: (): Promise<void> => Promise.resolve(),
  setAvailableFilters: (): Promise<void> => Promise.resolve(),
  clearFilters: (): Promise<void> => Promise.resolve(),
};

const ClassFilterContext = createContext(defaultContext);

export const useClassFilterReducer = (): [IState, React.Dispatch<Action>] =>
  useReducer((current: IState, action: Action): IState => {
    switch (action.type) {
      case 'SET_AVAILABLE_FILTERS':
        return {
          ...current,
          [action.section]: action.filters,
        };
      case 'SET_FILTERS':
        return {
          ...current,
          [action.section]: action.filters,
        };
      case 'CLEAR_FILTERS':
        return {
          ...current,
          [action.section]: {},
        };
      default:
        return { ...current };
    }
  }, defaultState);

const ClassFilterProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useClassFilterReducer();

  const classFilterContext = useMemo(
    () => ({
      state,
      setFilters: async (section: Section, filters: ClassFiltersT) => {
        dispatch({
          type: 'SET_FILTERS',
          section,
          filters,
        });
      },
      setAvailableFilters: async (
        section: AvailableSection,
        filters: ClassFiltersT,
      ) => {
        dispatch({
          type: 'SET_AVAILABLE_FILTERS',
          section,
          filters,
        });
      },
      clearFilters: async (section: Section) => {
        dispatch({
          type: 'CLEAR_FILTERS',
          section,
        });
      },
    }),
    [state], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ClassFilterContext.Provider value={classFilterContext}>
      {children}
    </ClassFilterContext.Provider>
  );
};

function useClassFilterContext(): IContext {
  return useContext(ClassFilterContext);
}

export { ClassFilterContext, ClassFilterProvider, useClassFilterContext };
