import { useState, useEffect, useCallback } from 'react';
import { useTreatments, useTrack } from '@splitsoftware/splitio-react';
import { useSelfQuery } from 'graphql/types';
import logger from 'utils/logger';

type SplitFlagsType = Record<string, boolean | object>;

const DEFAULT_FLAGS: SplitFlagsType = {
  'show-developer-menu': false,
  'fit-show-audio-article-on-web': false,
};

const treatmentsToFlags = (treatments: SplitIO.TreatmentsWithConfig) => {
  const flagsSet: SplitFlagsType = {};
  Object.keys(treatments).forEach((treatmentName) => {
    flagsSet[treatmentName] = treatments[treatmentName].treatment === 'on';
  });
  return flagsSet;
};

type TrackArgs = [
  eventType: string,
  value?: number,
  properties?: SplitIO.Properties,
];

type TrackFunction = (...args: TrackArgs) => Promise<void>;

const useSplit = () => {
  const treatments = useTreatments(Object.keys(DEFAULT_FLAGS));
  const flags = treatmentsToFlags(treatments);
  const { data } = useSelfQuery();
  const self = data?.self;

  const splitTrack = useTrack();

  // init as anonymous until have the user id: https:// help.split.io/hc/en-us/articles/360046771911-React-SDK-Lazy-initialization-of-Split-client
  const [config, setConfig] = useState<SplitIO.IBrowserSettings | undefined>({
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_IO_KEY || '',
      key: self?.id ?? 'anonymous',
    },
  });

  const track: TrackFunction = useCallback(
    async (eventType, value, props) => {
      logger.debug('Split.IO TRACK', { eventType }, props);
      const traffic = self?.id ? 'user' : 'anonymous';
      await splitTrack(traffic, eventType, value, props);
    },
    // The Split client does not provide a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [self?.id],
  );

  const [userAttributes, setUserAttributes] = useState<
    SplitIO.Attributes | undefined
  >();

  useEffect(() => {
    if (self?.id) {
      const { id, email, isTestUser, fitClient } = self;
      setConfig({
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_IO_KEY || '',
          key: id || '',
        },
      });

      setUserAttributes({
        email: email ?? '',
        isTestUser: isTestUser ?? false,
        clientId: fitClient?.id ?? '',
      });
    }
  }, [self]);

  return {
    flags,
    config,
    userAttributes,
    track,
  };
};

export default useSplit;
