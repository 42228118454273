import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLayerGroup,
  faDumbbell,
  faPerson,
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/common/Modal';
import { useGetOnDemandDetailsQuery } from 'graphql/types';
import useMixpanel from 'hooks/useMixpanel';
import { cleanUpWords } from 'utils/helpers';
import { computeUrl } from 'utils/image';
import { MEDIA_AR } from 'components/ContentTile';
import { QR_REDIRECT_PATH } from 'pages/RedirectQR';
import BookmarkButton from 'components/common/BookmarkButton';
import CopyButton from 'components/common/CopyButton';
import { getWistiaIdFromUrl } from './utils';

interface FiltersProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const ClassModal = ({ open, onClose, id }: FiltersProps) => {
  const track = useMixpanel();
  const navigate = useNavigate();
  const location = useLocation();
  const maxTileWidth = 458;
  const maxTileHeight = Math.round(maxTileWidth / MEDIA_AR);

  const { data: itemData, loading } = useGetOnDemandDetailsQuery({
    variables: {
      id,
    },
  });

  const item = itemData?.content;

  useEffect(() => {
    if (item && item.__typename === 'Video') {
      track('Screen View', {
        page_name: 'class_on_demand_detail',
        content_id: item.id,
      });
    }
  }, [track, item]);

  // we don't support audio or articles on web, so we redirect to the QR Code Redirect page
  // so the user can open the Exos app on their mobile device
  if (item?.__typename === 'Audio' || item?.__typename === 'Article') {
    navigate(`${QR_REDIRECT_PATH}${location.pathname}`);
    return null;
  }

  if (item?.__typename !== 'Video') {
    return null;
  }

  const mediaUrl = computeUrl(item?.heroPhoto, {
    width: maxTileWidth,
    height: maxTileHeight,
  });

  const equipment = item?.contentEquipment.map((e) => e.name).join(', ') || '';

  if (loading) {
    return (
      <Modal open={open} onClose={onClose}>
        <CircularProgress
          color="inherit"
          style={{ margin: '100px auto', display: 'block' }}
          aria-label="Loading"
        />
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      card={
        <Card
          square
          style={{
            position: 'relative',
          }}
        >
          <main>
            <BackgroundImage image={mediaUrl} title={item?.title || ''} />
            <CardContentStyled>
              <Metadata gutterBottom>{item.author}</Metadata>
              <Title role="heading" aria-level={1}>
                {item?.title}
              </Title>
              <Metadata
                role="heading"
                aria-level={2}
                gutterBottom
              >{`${Math.floor(
                Number(item?.time),
              )} min • ${item?.contentGenre?.name ?? ''}`}</Metadata>
            </CardContentStyled>

            <ActionsWrapper>
              {typeof item.saved === 'boolean' && (
                <BookmarkButton
                  contentId={id}
                  isSaved={item?.saved}
                  containerSize={34}
                  iconSize={14}
                />
              )}
              <CopyButton id={item.id} />
            </ActionsWrapper>
          </main>
        </Card>
      }
      button={
        <StartButton
          onClick={() => {
            navigate(location.pathname, { replace: true }); // Prevents modal from displaying on return from video
            navigate(
              `/video/full/${getWistiaIdFromUrl(
                item?.video || '',
              )}/${item?.id}`,
              { state: location.state },
            );
          }}
        >
          Start Class
        </StartButton>
      }
    >
      <>
        <Category dangerouslySetInnerHTML={{ __html: item?.html ?? '' }} />
        <Divider variant="fullWidth" />
        <Details>
          <span>
            <DetailContainer>
              {/* <Accessibility /> */}
              <FontAwesomeIcon icon={faPerson} size="lg" width={24} />
              <Label>Genre</Label>
            </DetailContainer>
            <Value>{item?.contentGenre?.name ?? ''}</Value>
          </span>
        </Details>
        <Divider variant="fullWidth" />
        <Details>
          <span>
            <DetailContainer>
              <FontAwesomeIcon icon={faDumbbell} width={24} />
              <Label>Equipment</Label>
            </DetailContainer>
            <Value>{equipment}</Value>
          </span>
        </Details>
        <Divider variant="fullWidth" />
        <Details>
          <span>
            <DetailContainer>
              <FontAwesomeIcon icon={faLayerGroup} width={24} />
              <Label>Collection</Label>
            </DetailContainer>
            <Value>{cleanUpWords(item?.brand || '')}</Value>
          </span>
        </Details>
        <Divider variant="fullWidth" />
      </>
    </Modal>
  );
};

export default ClassModal;

const Details = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '& span': {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const DetailContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  fontSize: '14px',
  lineHeight: '26px',
  margin: '18px 0 18px 15px',
  color: theme.colors.gray[800],
  textTransform: 'capitalize',
}));

const Value = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  fontSize: '14px',
  lineHeight: '26px',
  right: 0,
  textAlign: 'right',
  maxWidth: '250px',
  margin: '16px 0',
  color: theme.colors.gray[600],
  textTransform: 'capitalize',
  '& span': {
    display: 'block',
    margin: '16px 0',
  },
}));

const Category = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  fontSize: 14,
  lineHeight: 1.4,
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  color: theme.colors.gray[1],
  width: '100%',
  boxSizing: 'border-box',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 30.73%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))`,
  padding: theme.baseUnit * 3,
  paddingTop: `min(${theme.baseUnit * 3}px, 5vh)`,
  paddingBottom: `min(${theme.baseUnit * 3}px, 4vh)`,
}));

const BackgroundImage = styled(CardMedia)(() => ({
  backgroundImage:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 53.12%, rgba(0, 0, 0, 0.47) 85.94%)',
  height: 'min(256px, 30vh)',
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.sharp600,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '32px',
  color: theme.colors.gray['1'],
  marginTop: theme.baseUnit,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const Metadata = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  ...theme.typography.fontFamily.reader400,
  fontSize: 12,
  lineHeight: '12px',
}));

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  top: theme.baseUnit,
  right: theme.baseUnit,
  position: 'absolute',
  gap: theme.baseUnit,
}));

const StartButton = styled(Button)(({ theme }) => ({
  '&:hover, &:focus': {
    outlineColor: theme.colors.alias.textPrimary,
  },
}));
