import { ApolloLink, from } from '@apollo/client';
import type { ClientOptions } from '@teamexos/prince-sdk';
import { PrinceSdkProvider } from '@teamexos/prince-sdk';
import React, { useMemo } from 'react';
import { SentryLink } from 'apollo-link-sentry';
import { getTimezone } from 'utils/dates';
import { getCurrentToken } from '@teamexos/fit-shared';
import { sha256 } from 'crypto-hash';

import fragments from '../graphql/fragments.json';
import typePolicies from '../apollo/typePolicies';

const version = process.env.REACT_APP_VERSION ?? 'unknown';
const { possibleTypes } = fragments;

/**
 * Add custom headers that will be sent to the Shared Platform
 */
const addHeadersExtraLink = new ApolloLink((operation, forward) => {
  const headers = {
    'x-application-version': `web/${version}`,
    'x-application-name': 'fit-web',
    'x-timezone': getTimezone(),
  };
  operation.setContext({
    headers,
  });

  return forward(operation);
});

const sentryLink = new SentryLink();

const PrinceSDKProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const clientOptions: ClientOptions = useMemo(
    () => ({
      getToken: async () => {
        const tokens = await getCurrentToken();

        return tokens?.idToken?.toString();
      },
      clientUrl: process.env.REACT_APP_PRINCE_GQL_URL,
      wsUrl: process.env.REACT_APP_PRINCE_WS_URL,
      possibleTypes,
      apolloClientOptions: {
        name: 'heart-web',
        version,
      },
      debug: process.env.NODE_ENV === 'development',
      extraLink: from([addHeadersExtraLink, sentryLink]),
      typePolicies,
      sha256,
    }),
    [],
  );

  return (
    <PrinceSdkProvider clientOptions={clientOptions}>
      {children}
    </PrinceSdkProvider>
  );
};

export default PrinceSDKProvider;
