import React, { useCallback, useEffect } from 'react';
import {
  Outlet,
  Route,
  Navigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import { ClassFilterProvider } from 'contexts/classFilterContext';
import { ZonedDateTime } from '@js-joda/core';
import useIterable, { formatIterableDate } from 'hooks/useIterable';
import Header from 'components/common/Header';
import SnackBar from 'components/common/SnackBar';
import Home from 'pages/Home';
import FullScreen from 'pages/onDemand/FullScreen';
import Page404 from 'pages/404';
import RedirectQR, { QR_REDIRECT_PATH } from 'pages/RedirectQR';
import { supportedDeepLinks } from 'utils/deeplinks';
import * as Sentry from '@sentry/react';

import SplitProvider from 'contexts/splitContext';
import { useAuthenticatedLayoutQuery } from 'graphql/types';
import Developer from 'pages/Developer';
import { useMixpanel } from 'utils/mixpanel';
import { SentryRoutes } from 'utils/sentry';
import SavedClasses from 'pages/SavedClasses';
import packageInfo from '../../package.json';
import useLastActivity from '../hooks/useLastActivity';

const deeplinks = Object.keys(supportedDeepLinks);

const replaceModeByGenre = (search: string) => {
  const urlParamsSerialized = new URLSearchParams(search);
  const genre = urlParamsSerialized.get('mode');

  if (genre) {
    urlParamsSerialized.delete('mode');
    urlParamsSerialized.set('genre', genre);
  }

  return urlParamsSerialized.toString();
};

const replaceDeprecatedRoute = (path: string) => {
  const urlParamsSerialized = new URLSearchParams();
  const genre = path.split('/genre/')[1];
  const brand = path.split('/collection/')[1];

  if (genre) {
    urlParamsSerialized.set('genre', genre.toLowerCase());
  }
  if (brand) {
    urlParamsSerialized.set('brand', brand.toLowerCase());
  }

  return urlParamsSerialized.toString();
};

// do not show header on full screen video to avoid screen readers to detect it
const ConditionalHeader = () => {
  const location = useLocation();
  const isFullScreenRoute = !!matchPath(
    '/video/full/:wistiaId/:id',
    location.pathname,
  );
  if (isFullScreenRoute) {
    return null;
  }
  return <Header />;
};

const Authenticated = () => {
  const { iterableTrack, isIterableInitialized } = useIterable();
  const isVisible = usePageVisibility();
  const { data } = useAuthenticatedLayoutQuery();
  const { pathname, search } = useLocation();

  const mp = useMixpanel();

  useLastActivity();

  // Configure Sentry
  useEffect(() => {
    const scope = Sentry.getCurrentScope();

    scope.setTag('app_version', `${packageInfo.version}`);
    scope.setUser({
      id: data?.self?.id,
      ip_address: '{{auto}}',
    });

    mp?.identify(data?.self?.id);
  }, [mp, data?.self?.id]);

  const reportOpen = useCallback(() => {
    const appOpenTime = formatIterableDate(ZonedDateTime.now());
    if (appOpenTime) {
      iterableTrack('appOpen', {
        appName: 'fit',
        platform: 'web',
        appOpenTime,
      });
    }
  }, [iterableTrack]);

  useEffect(() => {
    if (isVisible && isIterableInitialized) {
      reportOpen();
    }
  }, [isVisible, isIterableInitialized, reportOpen]);

  return (
    <SplitProvider>
      <SnackBar />
      <ConditionalHeader />
      {/*
        NOTE: after add a new route, make sure to add it
        to the .pa11yci.js file to be included in the accessibility tests
      */}
      <SentryRoutes>
        <Route path="/video/full/:wistiaId/:id" element={<FullScreen />} />
        <Route
          element={
            <ClassFilterProvider>
              <Outlet />
            </ClassFilterProvider>
          }
        >
          {/* Redirect deep link to class genres */}
          <Route
            path="/ondemand"
            element={
              <Navigate
                to={{
                  pathname: '/',
                  search: replaceModeByGenre(search),
                }}
                replace
              />
            }
          />
          <Route
            path="/collection/*"
            element={
              <Navigate
                to={{
                  pathname: '/',
                  search: replaceDeprecatedRoute(pathname),
                }}
                replace
              />
            }
          />
          <Route
            path="/genre/*"
            element={
              <Navigate
                to={{
                  pathname: '/',
                  search: replaceDeprecatedRoute(pathname),
                }}
                replace
              />
            }
          />
        </Route>
        <Route path="/session/:scheduleEventId" element={<Home />} />
        <Route path="/ondemand/:contentId" element={<Home />} />
        <Route path="/class/:scheduleEventId" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/developer" element={<Developer />} />
        <Route path="/classes" element={<Navigate to="/" replace />} />
        <Route path="/classes/ondemand" element={<Navigate to="/" replace />} />
        <Route path="/pepsico" element={<Navigate to="/" replace />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/forgot-password" element={<Navigate to="/" replace />} />
        <Route path="/password-expired" element={<Navigate to="/" replace />} />
        <Route path="/callback" element={<Navigate to="/" replace />} />
        <Route
          path="/start/:email/:clientId?"
          element={<Navigate to="/" replace />}
        />
        <Route path="/saved/:contentId?" element={<SavedClasses />} />

        {/* Allow us to redirect any existing route to QRCode if we need to  */}
        <Route path={`${QR_REDIRECT_PATH}/*`} element={<RedirectQR />} />
        {/* Redirect deep links not supported to app */}
        {deeplinks.map((link) => (
          <Route path={link} key={link} element={<RedirectQR />} />
        ))}
        {/* Not Found */}
        <Route path="*" element={<Page404 />} />
        <Route path="page404" element={<Page404 />} />
      </SentryRoutes>
    </SplitProvider>
  );
};

export default Authenticated;
