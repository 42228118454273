import React, { useEffect, useRef, ReactElement } from 'react';
import { Fade, Button, Modal as MaterialUIModal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { HeaderButton } from './HeaderButton';

export interface ModalProps {
  /** Is modal open flag */
  open: boolean;
  /**
   * Additional className.
   * Will be applied too whole modal content wrapper.
   * You can specify Modal dimensions with this class
   */
  className?: string;
  /** Without this flag the Back Icon Button will not be rendered */
  withBackButton?: boolean;
  /**
   * Slot for Card content.
   * It will affect on `backButton` styles
   */
  card?: ReactElement;
  /**
   * Main content slot.
   * For passing multiply children use <React.Fragment> component
   */
  children?: ReactElement;
  /**
   * Button component witch will be extended by default props to fit design
   * so you can pass `Button` component with only `onClick` and `children` props
   */
  button?: ReactElement;
  /**
   * On close handler.
   * Will be called on outside click or on back button click
   */
  onClose: () => void;
}

interface BackButtonProps {
  absolute: boolean;
  visible: boolean;
  onClose: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  absolute,
  visible,
  onClose,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const focusTimeout = setTimeout(() => {
      if (visible) {
        buttonRef.current?.focus();
      }
    }, 0);

    return () => clearTimeout(focusTimeout);
  }, [visible]);

  if (absolute) {
    return (
      <BackButtonAbsolute ref={buttonRef} aria-label="back" onClick={onClose}>
        <Back />
      </BackButtonAbsolute>
    );
  }

  return (
    <BackButtonFixed ref={buttonRef} aria-label="back" onClick={onClose}>
      <Back />
    </BackButtonFixed>
  );
};

const ModalButton: React.FC<Pick<ModalProps, 'button'>> = ({ button }) => (
  <CustomButton
    variant="contained"
    color="primary"
    fullWidth
    {...button?.props}
  />
);

const Modal = ({
  open,
  onClose,
  withBackButton = true,
  className,
  children,
  card,
  button,
}: ModalProps) => (
  <ModalContainer open={open} onClose={onClose} closeAfterTransition>
    <Fade in={open}>
      <Wrapper className={className}>
        <div style={{ flexGrow: 0 }}>
          {withBackButton && (
            <BackButton visible={open} onClose={onClose} absolute={!!card} />
          )}
          {card}
        </div>
        <ContentWrapper>
          {Boolean(children) && (
            <ChildrenWrapper tabIndex={0}>{children}</ChildrenWrapper>
          )}
          {Boolean(button) && (
            <SubmitWrapper>
              <ModalButton button={button} />
            </SubmitWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Fade>
  </ModalContainer>
);

export default Modal;

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.aquamarine[300],
  color: theme.colors.gray[800],
  borderRadius: '80px',
  height: '56px',
  '&:hover': {
    backgroundColor: theme.colors.aquamarine[500],
  },
  ...theme.typography.fontFamily.sharp600,
}));

export const ModalContainer = styled(MaterialUIModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '458px',
  height: '100%',
  maxHeight: '680px',
  backgroundColor: theme.colors.gray['1'],
  display: 'flex',
  flexDirection: 'column',
}));

const BackButtonFixed = styled(HeaderButton)(({ theme }) => ({
  margin: theme.baseUnit,
  marginBottom: 'min(28px, 5vh)',
}));

const BackButtonAbsolute = styled(HeaderButton)(({ theme }) => ({
  position: 'absolute',
  top: `min(${theme.baseUnit}px, 1vh)`,
  left: theme.baseUnit,
  zIndex: 100,

  '& svg': {
    '& path': {
      stroke: theme.colors.alias.iconPrimary,
    },
  },

  '&:hover': {
    '& svg': {
      '& path': {
        stroke: theme.colors.alias.textContrast,
      },
    },
  },

  '&:focus-visible': {
    outline: `${theme.colors.alias.iconPrimary} auto thick`,
    outlineOffset: '3px',

    '& svg': {
      '& path': {
        stroke: theme.colors.alias.textContrast,
      },
    },
  },
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.baseUnit * 3,
  paddingBottom: theme.baseUnit * 3,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const ChildrenWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.baseUnit * 3,
  paddingRight: theme.baseUnit * 3,
  flexGrow: 1,
  maxHeight: '100%',
  overflow: 'auto',
}));

const SubmitWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.baseUnit * 3,
  paddingRight: theme.baseUnit * 3,
  marginTop: theme.baseUnit * 3,
}));
