import {
  faFileLines,
  faHeadphones,
  faVideo,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { OnDemandPreviewFragment } from 'graphql/types';

export const CONTENT_TILE_ICONS: Record<string, IconDefinition> = {
  video: faVideo,
  audio: faHeadphones,
  article: faFileLines,
};

export const getOnDemandClickEventName = (
  type?: OnDemandPreviewFragment['__typename'],
) => {
  if (type === 'Article') {
    return 'view_article';
  }
  return 'view_on_demand_class_detail';
};

export const getOnDemandEventLocation = (
  type?: OnDemandPreviewFragment['__typename'],
) => {
  if (type === 'Article') {
    return 'article';
  }
  return 'class_on_demand_detail';
};

export const getCompletionEventName = (
  type?: OnDemandPreviewFragment['__typename'],
) => {
  if (type === 'Article') {
    return 'article_read';
  }

  if (type === 'Audio') {
    return 'audio_complete';
  }

  if (type === 'Video') {
    return 'video_watched';
  }
  return '';
};

export const getWistiaIdFromUrl = (url: string): string => {
  const { pathname } = new URL(url);
  const paths = pathname.split('/');
  // url is like: https://wistia.com/embed/v1/<wistiaId>.m3u8
  // this will return <wistiaId>.m3u8 -> split by . and return the first element (wistiaId)
  return paths[paths.length - 1].split('.')[0];
};
