import React from 'react';
import { Divider } from '@mui/material';
import {
  faCrosshairs,
  faDumbbell,
  faLayerGroup,
  faList,
  faSignalBars,
} from '@fortawesome/pro-regular-svg-icons';
import { cleanUpWords } from 'utils/helpers';
import { OnDemandDetailsFragment } from 'graphql/types';
import DetailRow from './DetailRow';

type AttributeDetailsProps = {
  content: OnDemandDetailsFragment;
};

const AttributeDetails: React.FC<AttributeDetailsProps> = ({ content }) => (
  <>
    <Divider variant="fullWidth" />
    {'contentGenre' in content && content.contentGenre && (
      <DetailRow icon={faList} label="Genre" text={content.contentGenre.name} />
    )}
    {'contentLevel' in content && content.contentLevel && (
      <DetailRow
        icon={faSignalBars}
        label="Skill Level"
        text={content.contentLevel.name}
      />
    )}
    {'contentEquipment' in content && (
      <DetailRow
        icon={faDumbbell}
        label="Equipment"
        text={
          content.contentEquipment.map((eq) => eq.name).join(', ') || 'None'
        }
      />
    )}
    {'contentSplit' in content && content.contentSplit && (
      <DetailRow
        icon={faCrosshairs}
        label="Target Area"
        text={content.contentSplit.name}
      />
    )}
    {'brand' in content && content.brand && (
      <DetailRow
        icon={faLayerGroup}
        label="Brand"
        text={cleanUpWords(content.brand)}
      />
    )}
  </>
);

export default AttributeDetails;
