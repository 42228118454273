import { useCallback, useEffect, useRef } from 'react';
import { useUpdateProgressMutation } from 'graphql/types';
import throttle from 'lodash/throttle';
import useMixpanel from 'hooks/useMixpanel';

export const MINIMAL_ACCEPTABLE_PROGRESS = 0.7;

type CompletionMixpanelData = {
  event_category: string;
  event_location: string;
  event_name: string;
};

const useSendThrottledProgress = () => {
  const [updateProgress] = useUpdateProgressMutation();

  const sendProgress = useCallback(
    async (progress: number, contentId?: string) => {
      if (progress > 0) {
        if (!contentId) {
          return;
        }
        await updateProgress({
          variables: {
            contentId,
            progress: progress ?? 0,
          },
        });
      }
    },
    [updateProgress],
  );

  const track = useMixpanel();

  // it's necessary to use a ref here because the throttled function is not able to access/update the latest state
  const progressRef = useRef({
    isCompleted: false,
    currentProgress: 0,
  });

  const onProgress = useCallback(
    async (
      progress: number,
      contentId?: string,
      mpData?: CompletionMixpanelData,
      shouldTrackCompletionEvent = true, // the video player already tracks the completion event
    ) => {
      const { currentProgress, isCompleted } = progressRef.current;

      // Save progress if it's greater than the current progress
      // We also have this rule on the backend, so it's useless to save the same progress multiple times.
      if (progress >= currentProgress) {
        progressRef.current.currentProgress = progress;

        if (!isCompleted && progress >= MINIMAL_ACCEPTABLE_PROGRESS) {
          progressRef.current.isCompleted = true;
          if (shouldTrackCompletionEvent) {
            track('User Action', {
              content_id: contentId,
              event_type: 'active engagement',
              ...mpData,
            });
          }
        }

        sendProgress(progress, contentId);
      }
    },
    [sendProgress, track],
  );

  const throttledSaveProgress = useRef(
    throttle(
      (newProgress, contentId, mixPanelData, shouldTrackCompletionEvent) =>
        onProgress(
          newProgress,
          contentId,
          mixPanelData,
          shouldTrackCompletionEvent,
        ),
      1000,
      {
        leading: true,
      },
    ),
  );

  // Track progress when leaving the screen
  useEffect(
    // Note: this is _only_ a cleanup function
    () => () => {
      throttledSaveProgress.current.flush();
      onProgress(progressRef.current.currentProgress);
    },
    // Treat this as componentWillUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return throttledSaveProgress.current;
};

export default useSendThrottledProgress;
