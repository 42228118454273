import React from 'react';
import { usePlaybookTheme } from 'playbook';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Divider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

const DetailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.colors.alias.mainSurfaceDivider}`,
  padding: `${theme.baseUnit * 2}px 0`,
}));

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DetailRow = ({
  label,
  text,
  icon,
}: {
  label: string;
  text: string;
  icon: IconDefinition;
}) => {
  const theme = usePlaybookTheme();
  return (
    <>
      <DetailWrapper>
        <LabelWrapper>
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: theme.colors.alias.textSecondary,
              marginRight: theme.baseUnit,
            }}
          />
          <Typography style={{ ...theme.typography.textVariant.label.l }}>
            {label}
          </Typography>
        </LabelWrapper>
        <Typography>{text}</Typography>
      </DetailWrapper>
      <Divider variant="fullWidth" />
    </>
  );
};
export default DetailRow;
