import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { usePlaybookTheme } from 'playbook';
import useMixpanel from 'hooks/useMixpanel';
import styled from '@emotion/styled';
import { HeaderButton } from './HeaderButton';

enum ClipboardCopy {
  CopyLink = 'Copy link',
  Copied = 'Copied to clipboard',
}

const InvertedButton = styled(HeaderButton)(({ theme }) => ({
  border: `1px solid ${theme.colors.alias.primary}`,
  '&:hover, &:focus-visible': {
    backgroundColor: theme.colors.alias.primary,
    border: `1px solid ${theme.colors.alias.mainBackground}`,
  },
}));

const LinkCopied = ({ isCopied }: { isCopied: boolean }) => {
  const { colors } = usePlaybookTheme();
  if (isCopied) {
    return (
      <>
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={colors.alias.statusSuccess}
          width={14}
        />{' '}
        {ClipboardCopy.Copied}
      </>
    );
  }
  return ClipboardCopy.CopyLink;
};

type CopyButtonProps = {
  id: string;
  inverted?: boolean;
  size?: number;
  mixpanelData?: Record<string, unknown>;
};

const CopyButton: React.FC<CopyButtonProps> = ({
  id,
  inverted = false,
  size = 34,
  mixpanelData,
}) => {
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const track = useMixpanel();
  const handleCopyLink = () => {
    setCopiedLink(true);
    // We don't want to copy the full path because if the results have been filtered
    // if could contain the collection in the path and only
    // these links will work when shared in the Fit app
    navigator.clipboard.writeText(`${window.location.origin}/ondemand/${id}`);
    track('User Action', {
      content_id: id,
      event_category: 'on demand',
      event_location: 'class_on_demand_detail',
      event_name: 'copy_link',
      event_type: 'screen interaction',
      ...mixpanelData,
    });
  };
  const handleResetCopy = () => setCopiedLink(false);

  const CurrentButton = inverted ? InvertedButton : HeaderButton;

  return (
    <Tooltip title={<LinkCopied isCopied={copiedLink} />} placement="top">
      <CurrentButton
        onClick={handleCopyLink}
        onMouseEnter={handleResetCopy}
        onFocus={handleResetCopy}
        aria-label={copiedLink ? ClipboardCopy.Copied : ClipboardCopy.CopyLink}
        size={size}
      >
        <FontAwesomeIcon icon={faLinkSimple} />
      </CurrentButton>
    </Tooltip>
  );
};

export default CopyButton;
