import styled from '@emotion/styled';

export const HeaderButton = styled('button')<{ size?: number }>(
  ({ theme, size = 32 }) => ({
    appearance: 'none',
    borderRadius: '50%',
    background: 'none',
    color: theme.colors.alias.iconPrimary,
    border: `1px solid ${theme.colors.alias.mainBackground}`,
    width: size,
    height: size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
    backgroundColor: theme.colors.alias.mainBackground,

    '&:hover, &:focus-visible': {
      backgroundColor: 'transparent',
      color: theme.colors.alias.textContrast,
      border: `1px solid ${theme.colors.alias.mainBackground}`,
      opacity: 1,
    },
  }),
);
